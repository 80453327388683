.cardsContainer {
    padding: 15px;
    
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

    section {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .card {
            display: flex;
            flex-direction: column;

            min-width: 15rem;
            max-width: 15rem;
            height: auto;

            padding: 1.5rem;

            border: 2px solid $green-400;
            opacity: 0.89;

            border-radius: 1rem;
            margin: 1rem;

            transition: 0.3s ease-in;

            span h3, span h2 {
                text-align: center;
                font-size: 1.5rem;
                color: $green-1000;
            }

            .label {
                width: 100%;

                background-color: $green-600;
                
                border-radius: 1rem;
                padding: 0.5rem;
                margin: 0;
                cursor: pointer;
                
                color: #fff;
                text-align: center;
            }

            &:hover {
                transition: 0.3s ease-in;
                -webkit-transition: 0.3s ease-in;
                opacity: 1;
            }
        }

        @media(max-width: 590px){
            flex-direction: column;
            align-items: center;
        }
    }
}