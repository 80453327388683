.newUserContainer {
    margin: 0px 40px 0px 60px;
    .cardNewUser {
        padding: 15px;
        .cardNewUserHeader {
            display: flex;
            justify-content: space-between;
            text-align: center;
            .logoSection {
                display: flex;
                align-items: center;
                text-align: center;
                height: 100%;
                .logo {
                    width: 5%;
                    margin-right: 0.5rem;
                    transform: translateY(0px);
                }
                
                .logoTitle {
                    margin: 0;
                    text-align: start;
                    font-size: 1.575rem;
                    color: $green-1000;
                }
            }
            .button {
                background-color: $green-50;
                color: $skin5;
                border: 0;
                border-radius: 0.4rem;
            }
        }

        .profileImageContainer {
            cursor: pointer;
            height: 6rem;
            width: auto;
            border-radius: 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            margin: 0 1rem 1rem 0;
            
            .label {
                margin: 0;
                height: 100%;
                width: auto;
                border-radius: 5rem;
                .profileImage {
                    height: 100%;
                    border: 1px solid $green-700;
                    opacity: 0.7;
                    border-radius: 5rem;
                    transition: width .5s ease, opacity .5s ease;
                }
                .profileImage:hover {
                    opacity: 1;
                }
            }
            .label:after {
                content: 'Alterar';
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                bottom: 100%;
                text-align: center;
                border-radius: 5rem;
                margin: auto;
                height: 0.094rem;
                width: 100%;
                height: 100%;
                opacity: 0.3;
                background-color: #000;
                
                transition: width .5s ease, opacity .5s ease;
            }


            .label:hover:after {
                cursor: pointer;
                color: white;
                opacity: 0.5;
                .profileImage:hover {
                    opacity: 1;
                }
            }
            .input {
                display: none;
            }

        }
    }
}