.modalBody {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    .label {
        text-align: center;
    }
    .button {
        background-color: $green-600;
        color: $white;
        border: 0;
        border-radius: 0.4rem;
        margin-bottom: 1rem;
    }
}