.termsContainer {
    margin-top: 2rem;

    .openCardHeader, .closedCardHeader  {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .iconCard {
            transition: 0.5s;
        }
    }

    .openCardHeader {
        .iconCard {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }

    footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $sidebar-text-dark;
        color: #fff;
        padding: 2rem;

        .socialIcons {
            margin: 0 0 0.5rem 0;
        }

        .socialIcons a {
            padding: 0.8rem;
            font-size: 26px;
        }
        span {
            text-align: center;
        }
    }

}