.optionsContainer {
    display: flex;
    flex-direction: column-reverse;

    .options {
        width: auto;

        display: flex;
        flex-direction: column;
        
        position: absolute;
        z-index: 1;
        transform: translate(2.75rem, 25%);

        border-radius: 5px;
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
        
        .option {
            background-color: #fff !important;
            color: #000;
            border: none;
            transform: none;
            transition: 0.4s;
            
            &:hover {
                background-color: rgb(245, 245, 245) !important;
            }
        }

        @media(max-width: 1400px) {
            transform: translate(2.35rem, 25%);
        }
    }

    .optionsClosed {
        display: none;
    }

    .optionsButton {
        width: 2rem;
        border-radius: 5px;
    }
}