#modal {
    #modalBody {
        height: 24rem;
        
        overflow: auto;

        padding-bottom: 0;
        
        #alertHistoric {
            width: 100%;

            background-color: #f8d7da;
            box-shadow: none;
            color: #721c24;

            display: flex;
            justify-content: space-between;
            align-items: center;
            
            margin: 0.2rem;
            padding: 0.5rem 0.8rem 0.5rem 0.8rem;
            border: 1px solid #721c24;

            
            label {
                cursor: pointer;
                margin: 0;
            }
        }

        .selectFormsButton {
            width: 100%;

            background-color: $green-400;
            box-shadow: none;
            color: $green-1000;
            font-weight: bold;

            display: flex;
            justify-content: space-between;
            align-items: center;
            
            margin: 0.2rem;
            padding: 0.5rem 0.8rem 0.5rem 0.8rem;
            border: 1px solid $green-800;

            
            label {
                cursor: pointer;
                margin: 0;
            }
        }

        .detailsCard {
            background-color: #f8d7da;
            padding: 0.4rem;
        }

        #navLink {
            cursor: pointer !important;
        }

        .downloadContainer,
        .downloadError {
            height: 15rem;

            display: flex;
            flex-direction: column;
            align-items: center;

            border: 2px dotted $green-400;
            border-radius: 0.5rem;
            margin-top: 0.6rem;
            
            
            text-align: center;
            color: $green-600;

            .bodyButton {
                background-color: transparent;
            
                padding: 0;
                border: 0;
                box-shadow: none;
    
                color: $green-600;
                font-size: 5rem;
            }
        }

        .downloadError {
            border-color: var(--danger);
            color: var(--danger);
            .bodyButton {
                color: var(--danger);
            }
            .buttonError {
                width: 40%;
                
                background-color: transparent;

                margin-top: 0.8rem;
                box-shadow: none;
                border: 2px dotted var(--danger);
                border-radius: 0.5rem;
    
                color: var(--danger);
            }
        }

        .buildingContainer {
            display: flex;
            flex-direction: column;
            align-items: center;

            text-align: center;
            .buildingImage {
                max-height: 14rem;
                margin: 1rem;
            }
            .buildingText {
                font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
                color: $green-700;
                font-size: 1.2rem;
            }
        }
        
        .progressContainer {
            height: 20rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            .iconList {
                width: 100%;

                display: flex;
                flex-direction: column;

                .icon {
                    padding: 0 0.2rem 0 0.2rem;

                    display: flex;
                    justify-content: space-between;

                    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
                    font-weight: bolder;
                    color: $green-400;
                }
                .icon:first-child() {
                    color: $green-700;
                }
                .icon:last-child() {
                    color: var(--danger);
                }
            }
        }
    }

    #modalFooter {
        display: flex;
        justify-content: flex-start;

        .buttonError {
            width: 100%;

            background-color: transparent;

            display: flex;
            justify-content: space-between;
            align-items: center;
            
            padding: 0;
            border: 0;
            box-shadow: none;
            
            font-size: 1rem;
            font-weight: bolder;
            color: $green-700;
            text-align: start;
        }
    } 

    .importProgress {
        background-color: $green-600;
        
        border-radius: 0.6rem 0.6rem 0 0;
        box-shadow: none;
        pointer-events: all;
        
        position: relative;
        bottom: 0;
    }

    .importProgress:hover {
        transform: translateY(0px);
    }

    #historicFooter {
        width: 100%;

        text-align: center;
        border-top: none;
        p {
            width: 100%;
            
            margin: 0;

            font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
            color: var(--skin5);
            opacity: 0.6;
        }
    }
}