#modal {
    #modalHeaderAssistance {
        color: $green-900;
        height: auto;
        width: 100%;
        
        text-align: center;
        
        h5 {
            font-size: 2em;
            width: 100%;
        }
    }

    #modalBody {
        .questionContainer {
            font-size: 1em;
            font-weight: bolder;
        
            padding: 1em 0 1em 0;

            .labelContainer {
                height: auto;

                display: flex;
                justify-content: space-between;
                align-items: center;

                .previewImage {
                    height: auto;

                    padding: 0;
                    margin-bottom: 8px;
                    transform: none;

                    color: #3e5569;
                    background-color: transparent;
                    border: none;

                    box-shadow: none;
                }
            }
    
            .inputArea {
                height: 20em;
            }
    
            .selected {
                border-color: $green-400;
            }
        }
    }

    #modalFooterAssistance {
        display: flex;
        justify-content: end;
        align-items: center;
        flex-wrap: wrap;

        .footerAlert {
            width: auto;

            display: flex;
            flex-direction: row;

            position: absolute;
            left: 1.2em;

            .alert {
                padding: 0.3em 0.6em 0.3em 0.6em;
                margin: 0;
            }

        }

        .buttonGroup {
            width: auto;

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            button {
                width: 7em;
                margin: 0 0.2em 0 0.2em;
            }
        }
    }
}

#modalImagePreview {
    width: 100%;
    height: auto;
    padding: 0;
    
    img {
        max-width: 100%;
        height: auto;
    }
}