.importContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 2rem;
    
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    color: $green-1000;

    .buttonsGroup {
        display: flex;
        border: 0;
        margin: 0.5rem;

        .optionsButton {
            width: 1rem;
            height: 1rem;

            background-color: transparent;
            box-shadow: none;
            
            font-size: 1rem;
            color: $green-700;

            padding: 0;
            margin: 0;
            border: none;

            position: relative;
            left: 16.5rem;
        }

        .button {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            min-width: 20rem;
            max-width: 20rem;
            min-height: 15rem;
            max-height: 15rem;

            padding: 0 2rem 0 2rem;

            border: 2px solid $green-400;
            opacity: 0.89;

            border-radius: 1rem;
            margin: 1rem;

            transition: 0.3s ease-in;

            span h3, span h5, span h2 {
                text-align: center;
            }
            span h2 {
                padding-top: 10%;
            }

            span h3 {
                margin-top: 6%;
            }
            input[type='file'] {
                display: none;
            }

            .label {
                width: 100%;

                background-color: $green-600;
                
                border-radius: 1rem;
                padding: 0.5rem;
                margin-top: 5%;
                cursor: pointer;
                
                color: #fff;
                text-align: center;
            }

            .comments {
                min-height: 1rem;

                text-align: center;
                padding: 0 2rem 0 2rem;
                font-size: 0.7rem;
                margin-bottom: 0;
                flex-wrap: wrap;
            }
        }

        .button:hover {
            transition: 0.3s ease-in;
            -webkit-transition: 0.3s ease-in;
            opacity: 1;
        }

        .sendGroup {
            text-align: center;

            .sendGroupLabel {
                font-size: 0.7rem;
            }

            .sendGroupLabel:after {
                overflow: hidden;
                display: inline-block;
                vertical-align: bottom;
                -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
                animation: ellipsis steps(4,end) 900ms infinite;
                content: "\2026"; /* ascii code for the ellipsis character */
                width: 0px;
              }

            .cancelButton, .sendButton {
                background-color: transparent;
                
                border: none;
                padding: 0;
                box-shadow: none;
                
                font-size: 1.5rem;
            }

            .cancelButton {
                color: $danger;
            }

            .sendButton {
                color: $green-400;
            }
        }

    }
    @media(max-width: 1094px){
        .buttonsGroup {
            flex-direction: column;
        }
    }
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: $green-600;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 3em;
  font-weight: bold;
  fill: $green-600;
}

@keyframes ellipsis {
    to {
      width: 1.25em;    
    }
  }
  
  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;    
    }   
}