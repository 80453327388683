.newNotificationContainer {
    width: 100%;
    height: auto;

    .formsContainer {
        width: 100%;

        padding: 1rem;

        .logoSection {
            display: flex;
            align-items: center;
            text-align: center;
            height: 100%;

            margin-bottom: 1rem;

            .logo {
                width: 1.5rem;
                margin-right: 0.5rem;
                transform: translateY(0px);
            }
            
            .logoTitle {
                margin: 0;
                text-align: start;
                font-size: 1.575rem;
                color: $green-1000;
            }
        }

        .buttonMaker {
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
                margin: 0;
            }

            span {
                background-color: transparent;
                color: $green-400;

                cursor: pointer;

                transition: 0.5s;

                margin-right: .5rem;

                &:hover {
                    color: $green-600;
                }
            }
        }

        .newNotificationFooter {
            width: 100%;

            display: flex;
            justify-content: end;
            align-items: center;

            button {
                margin-left: .5rem;
            }
        }

        .inputImage {
            display: flex;
        }

        .previewImage {
            height: auto;

            padding: 0;
            margin-bottom: 8px;
            transform: none;

            color: #3e5569;
            background-color: transparent;
            border: none;

            box-shadow: none;
        }
    }
}

.buttonsOrder {
    height: 10rem !important;
    padding: 0;

    overflow: auto !important;

    button {
        height: max-content;
        width: max-content;
        
        padding: 0;
        margin: 0;
        border: 0;
        
        box-shadow: none;
        background-color: transparent;
        color: $danger;
        text-align: center;

        transition: .5s;

        &:hover {
            background-color: transparent;
            color: $danger;
            box-shadow: none;
        }
    }
}

.notificationImageContainer {
    height: 4rem;

    img {
        height: 100%;
        object-fit: cover;
    }
}
