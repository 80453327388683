.bodyEditSection {
    display: flex;
    .row {
        width: 40%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin: 0;

        .audioContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1rem;

            border: 1px solid $green-700;
            
            .audioInputLabel {
                width: auto;
                display: inline-block;
                text-align: center;
                color: $green-700;
                padding: 0rem 0.4rem 0 0.4rem;
                cursor: pointer;
                &:after {
                    content: '';
                    display: block;
                    margin: auto;
                    height: 0.014rem;
                    width: 0px;
                    transition: width 0.5s ease, background-color 0.5s ease;
                }
                &:hover:after,
                &:hover:focus:after {
                  width: 100%;
                  background: $green-700;
                }
            }

            #audioInput {
                display: none;
            }
        }

        .formGroup {
            display: flex;
            flex-direction: column;
            img {
                width: 100%;
                height: auto;
            }
        
            .mainImageLabel {
                width: 100%;
                border: 1px solid $green-700;
                text-align: center;
                color: $green-700;
                cursor: pointer;
                transition: 0.5s;
                #mainImage {
                    display: none;
                }
                &:hover {
                    background-color: $green-700;
                    color: #fff;
                }
            }
        
        
            @media(max-width: 900px){
                img {
                    width: 100%;
                    height: auto;
                }
        
                .mainImageLabel {
                    width: 100%;
                }
            }
        
        }
    }

    .textEdit {
        width: 60%;
        min-height: 100%;
        margin: 0 0 0 1rem;

        .ql-editor {
            min-height: 20.5rem;
            max-height: 20.5rem;
            overflow-y: auto;
        }
    }

    @media(max-width: 940px){
        flex-direction: column;

        .row, .textEdit {
            width: 100%;
            padding: 15px 15px 0 15px;
            margin: 0;
        }

        .textEdit {
            margin-bottom: 15px;
            padding-top: 0;
        }

    }
}