.editUserContainer {
    margin: 0px 40px 0px 60px;
    .cardEditUser {
        padding: 15px;
        .cardEditUserHeader {
            display: flex;
            justify-content: space-between;
            text-align: center;
            .logoSection {
                display: flex;
                align-items: center;
                text-align: center;
                height: 100%;
                .logo {
                    width: 5%;
                    margin-right: 0.5rem;
                    transform: translateY(0px);
                }
                
                .logoTitle {
                    text-align: start;
                    font-size: 1.575rem;
                    color: $green-1000;
                }
            }
            .button {
                background-color: $green-50;
                color: $skin5;
                border: 0;
                border-radius: 0.4rem;
            }
        }
    }
    .editProjectUserContainer {
        padding: 15px;
        .cardEditProjectUserHeader {
            display: flex;
            justify-content: space-between;
            text-align: center;
            .logoSection {
                display: flex;
                align-items: center;
                text-align: center;
                height: 100%;
                .logo {
                    width: 5%;
                    margin-right: 0.5rem;
                    transform: translateY(0px);
                }
                
                .logoTitle {
                    margin: 0;
                    text-align: start;
                    font-size: 1.575rem;
                    color: $green-1000;
                }
            }
        }
    }

    .imageUploadContainer {
        display: flex; 
        align-items: center; 
        margin: 10px 20px 40px 20px; 

        .imageUpload {
            margin-right: 160px; 
            border-radius: 30px;
            width: 40%; 
            padding: 25px 25px; 
            border: 2px dashed  #66bb6a;
            display: flex; 
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .imageUploadLogo {
                width: 28%;
                margin-bottom: 10px;
            }
            .imageUploadTitle {
                font-size: 0.8rem;
                text-align: center;
            }
            #image {
                width: 120px;
                overflow: hidden;
            }

            @media(max-width: 900px){
                width: 100%; 
                margin: 0 0 10px 0;
            }
        }
        .userImage {
            display: flex; 
            align-items: center; 
            justify-content: center; 
            width: 230px; 
            height: 230px; 
            border-radius: 50%;
            .profileImage {
                height: 100%;
                border: 1px solid $green-700;
                opacity: 0.7;
                border-radius: 50%;
                transition: width .5s ease, opacity .5s ease;
            }
            .profileImage:hover {
                opacity: 1;
            }
        }
    }

    .buttonGroups {
        display: flex;
        align-items: center;

        .saveButton,
        .cancelButton {
            width: 120px; 
            border-radius: 0.3rem; 
            height: 1.934rem;
            margin-right: 1rem;
        } 
    }
}