.communityContainer {
  margin: 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  .postNotFoundContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .postNotFound {
      height: auto;
      width: 55%;

      margin: 1.5rem 0 0 0;
    }

    label {
      margin: 1.5rem;

      color: $green-700;
    }
  }

  .communityCard {
    margin: 1.5rem 0 0 0;
    width: 80%;

    padding: 0.5rem 1rem 1rem 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    word-wrap: break-word;

    .communityLogo {
      height: auto;
      width: 8rem;
    }

    .postImage {
      height: auto;
      width: 50%;

      margin: 0.5rem 0 0 0;
    }

    audio,
    h5,
    p {
      width: 100%;
      margin: 0.5rem 0 0 0;
      text-align: justify;

      a {
        color: #3e5569;
      }

      @media (max-width: "700px") {
        img {
          width: 100%;
        }
      }
    }

    @media (max-width: "700px") {
      width: 85%;

      .communityLogo {
        width: 25%;
      }

      .postImage {
        width: 65%;
      }
    }

    @media (max-width: "450px") {
      width: 100%;

      .postImage {
        width: 85%;
      }
    }
  }
}

.badgeCount {
  width: 2rem;
  background-color: $green-800;
}
