#modal {
    #modalBody {
        .fallback-spinner {
            height: 100%;
        }
        .progressBarContainer {
            width: 100%;
            
            display: flex;
            align-items: center;
            justify-content: space-around;

            padding: 1.5em;

            span {
                width: 40%;
                padding: 0 0.2rem 0 0.2rem;
                max-height: 12rem;
                word-wrap: break-word;

                overflow-y: auto;

                font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
                color: $green-1000;

                p {
                    padding: 0.1rem;
                }

                p:last-child() {
                    color: #41464b;
                    background-color: #e2e3e5;
                    border-radius: 5px;
                }
            }

        }
    }

    .transferButton,
    .cancelButton {
        transform: none;
        border: none;
        border-radius: 5px;
        transition: 0.3s;

        &:hover {
            opacity: 0.9;
        }
    }

    .transferButton {
        background-color: $success;
    }

    .cancelButton {
        background-color: $danger;
    }
}