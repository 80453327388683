.auth-wrapper {
  min-height: 100vh;
  position: relative;
  .auth-box {
    background: $green-dark;
    padding: 20px;
    box-shadow: $shadow;
    max-width: 400px;
    width: 90%;
    margin: 10% 0;
    .logo {
      text-align: center;
    }
    &.on-sidebar {
      top: 0px;
      right: 0px;
      height: 100%;
      margin: 0px;
      position: absolute;
    }
  }
  #recoverform {
    display: none;
  }
  .auth-sidebar {
    position: fixed;
    height: 100%;
    right: 0px;
    overflow: auto;
    margin: 0px;
    top: 0px;
  }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before{
  background-color: $green-500;
  border: none;
}

.subtitle-login {
  font-weight: 800;
  margin: 10% 0 5% 0;
  color: $green-50;
}

.title-login {
  font-weight: 800;
  margin-top: 15%;
  color: $green-50;
}

.title-new-password {
  font-weight: 800;
  margin-top: 15%;
  color: $green-50;
}


.text-login{
  margin-bottom: 10%;
  color: $green-50;
}

.logo-img {
  width: 70%;
  margin-top: 5%;
}

.error {
  color: $danger;
}

.btn-light {
  font-weight: 800;
  background-color: $green-300;
  color: $green-1200;
  font-size: 1.2rem;
  border: none;
  margin: auto
}

.btn-light:hover {
  background-color: $green-700;
  color: $green-100;
}

.loginform{
  padding: 0;
  margin: auto;
}

@include media-breakpoint-down(sm) {
  .auth-wrapper {
    .auth-sidebar {
      position: relative;
      max-width: 50%;
      width: 50%;
      margin: 40px 0 60px;
    }
    .demo-text {
      margin-top: 30px;
    }
  }
}
