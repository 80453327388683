#TrouphiesContainer {
  overflow-x: auto;

  display: flex;
  justify-content: center;

  #TrouphyCol {
    cursor: pointer;
    opacity: .5;

    &.selected {
      opacity: 1;
      border-bottom: 2px solid $green-500;
    }

    &:hover {
      border-bottom: 2px solid $green-400;
    }
  }
}

.listTrailContainer {
  width: 100%;

  display: flex;
  align-items: center;
  overflow-x: auto;

  .listTrailItem {
    margin-left: 25px;

    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    cursor: pointer;
    transition: all .5s;
    
    > div {
      opacity: .6;
      width: 100px;
      height: 100px;
      
      border: 8px solid rgba(172, 173, 173, 0.137);
      border-radius: 50%;
      background-color: transparent;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 2.5rem;
      color: rgba(0, 0, 0, 0);

      transition: ease-in .2s;
      
      &:hover {
        color: rgba(0, 0, 0, 0.556);

        img {
          display: none;
        }
        
      }
    }


    p {
      max-width: 60px;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      text-align: left;
    }
  }
}