.assistanceContainer {
    display: flex;
    flex-direction: column;
    align-items: left;

    padding: 2rem;

    
    
    h2 {
        font-size: 2.5rem;
        font-weight: 600;

        color: $green-900;
        text-align: center;
    }

    h5, h4 {
        color: #394F3F;
    }

    .headerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            color: #D3461E;
        }

        .editAssistance, 
        .deleteAssistanceUser,
        .deleteAssistance {
            height: auto;
            width: auto;
    
            display: flex;
            align-items: center;
            justify-content: space-around;
    
            background-color: transparent;
            color: #394F3F;
            
            border: none;
            box-shadow: none;
        }
        
        .deleteAssistanceUser,
        .deleteAssistance {
            color: #D3461E;
        }
    }

    .farmerInformation {
        .farmerTable {
            background-color: #F2F5F3;
            border: none;
            
            .farmerName {
                padding:  0.5rem !important;
                cursor: pointer;
            }
        }
    }

    .assistanceDetails {
        margin-top: 4rem;

        .assistanceTable {
            background-color: #F2F5F3;
            th {
                padding:  0.5rem !important;
            }

            td {
                border: 2px solid #ffff;
            }
        }
    }
    
    .techInformation,
    .farmerInformation {
        margin-top: 2rem;
    }
}