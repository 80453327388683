.filterContainer {
    display: flex;
    flex-direction: row !important;
    padding: 1rem;
    width: 100%;
    height: 15em;
    margin-right: 1rem;
    max-height: 15em;

    overflow-x: auto;
    white-space: nowrap;

    animation-name: size;
    animation-duration: 1s; 
    animation-fill-mode: forwards;

    .label {
        color: $green-900;
    }

    .simpleFilterContainer {
        padding: 0 2em 0 2em;
        overflow-y: auto;
        white-space: nowrap;
        scrollbar-width: 0;
        .simpleFilterButtonGroup {
            display: flex;
            flex-direction: column;
            .simpleFilterButton {
                font-size: 0.7em;
                color: $green-900;
                min-width: auto;
                max-width: 10em;
                max-height: 2em;
                
                background-color: transparent;
                border: 1px solid $green-900;
                border-radius: 1rem;
                padding: 0.2em;
                margin: 0.5em;
                flex: 1;
            }
            .simpleFilterButton:hover {
                background-color: $green-600;
                color: $skin6;
            }

            .simpleFilterButtonSelected {
                font-size: 0.7em;
                color: $skin6;
                min-width: auto;
                max-width: 10em;
                max-height: 2em;
                
                background-color: $green-600;
                border: 1px solid $green-900;
                border-radius: 1rem;
                padding: 0.2em;
                margin: 0.5em;
                flex: 1;
            }
        }
    }

    .projectsFilterContainer {
        border-left: 1px solid #41475535;
        
        padding: 0 2em 0 2em;
        overflow-y: auto;
        white-space: nowrap;
        scrollbar-width: 0;
        text-align: center;

        .projectsFilterButtonGroup {
            display: flex;
            flex-direction: column;
            min-width: max-content;

            .projectsFilterButton {
                font-size: 0.7em;
                color: $green-900;
                max-height: 2em;
                
                background-color: transparent;
                border: 1px solid $green-900;
                border-radius: 1rem;
                padding: 0.2em;
                margin: 0.5em;
                flex: 1;
            }
            .projectsFilterButton:hover {
                background-color: $green-600;
                color: $skin6;
            }

            .projectsFilterButtonSelected {
                font-size: 0.7em;
                color: $skin6;
                min-width: max-content;
                max-height: 2em;
                
                background-color: $green-600;
                border: 1px solid $green-900;
                border-radius: 1rem;
                padding: 0.2em;
                margin: 0.5em;
                flex: 1;
            }

        }
    }

    .orderFilterContainer {
        border-left: 1px solid #41475535;
        
        padding: 0 2em 0 2em;
        overflow-y: auto;
        white-space: nowrap;
        scrollbar-width: 0;
        width: auto;
        text-align: center;

        .orderFilterButtonGroup {
            display: flex;
            flex-direction: column;
            min-width: max-content;

            .orderFilterButton {
                font-size: 0.7em;
                color: $green-900;
                width: auto;
                max-height: 2em;
                
                background-color: transparent;
                border: 1px solid $green-900;
                border-radius: 1rem;
                padding: 0.2em;
                margin: 0.5em;
                flex: 1;
            }
            .orderFilterButton:hover {
                background-color: $green-600;
                color: $skin6;
            }

            .orderFilterButtonSelected {
                font-size: 0.7em;
                color: $skin6;
                min-width: auto;
                max-height: 2em;
                
                background-color: $green-600;
                border: 1px solid $green-900;
                border-radius: 1rem;
                padding: 0.2em;
                margin: 0.5em;
                flex: 1;
            }
        }
    }

    .otherOptionsContainer {
        border-left: 1px solid #41475535;
        
        padding: 0 2em 0 2em;
        overflow-y: auto;
        white-space: nowrap;
        width: auto;
        text-align: center;

        .otherOptionsGroup {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            
            .otherOptionsBadge {
                background-color: $green-800;
            }

            .pageInput {
                padding: 0;
                border-radius: 1;                
            }

            .initialDateContainer,
            .endDateContainer {
                width: 100%;
                
                text-align: left;

                margin: 1rem 0 0 0;

                label {
                    font-weight: bold;
                }
            }
        }
    }

    .searchContainer {
        border-left: 1px solid #41475535;
        padding: 0 4em 0 4em;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        overflow-y: auto;

        .searchGroup {
            display: flex;
            border: 0.1em solid $green-900;
            height: calc(1.5em + 0.5rem + 2px);
            border-radius: 1em;

            #searchInput {
                border-radius: 10em;
                text-align: center;
                border: 0;
            }

            .buttonSearchIcon,
            .helpButton {
                background-color: transparent;
                border: 0;
                box-shadow: none;
                color: $skin5;
            }

            .buttonSearchIcon:hover,
            .helpButton:hover {
                border: 0;
                outline: none;
            }

        }

        .searchButton {
            background-color: $green-600;
            margin-top: 0.5em;
            width: 100%;
            border-radius: 1em;
            border: 0.1em solid $green-900;
        }

        &.only {
            width: 100%;
            border: none;
            padding: 0;
        }

        .searchButton:hover {
            background-color: $green-700;
        }
    }

    .simpleFilterContainer::-webkit-scrollbar,
    .projectsFilterContainer::-webkit-scrollbar,
    .orderFilterContainer::-webkit-scrollbar,
    .otherOptionsContainer::-webkit-scrollbar,
    .searchContainer::-webkit-scrollbar {
        width: 0px;
    }

}

@keyframes size {
    0% {
      height: 0em;
    }
    100% {
      height: 15em;
    }
}