.pageContainer {
    margin: 15px 40px 10px 60px;
    .cardPage {
        padding: 15px 15px 0 15px;
        min-height: 83.5vh;
        .cardPageHeader {
            display: flex;
            justify-content: space-between;
            text-align: center;
            margin-bottom: 1rem;
            .logoSection {
                display: flex;
                align-items: center;
                text-align: center;
                height: 100%;
                .logo {
                    width: 5%;
                    margin-right: 0.5rem;
                    transform: translateY(0px);
                }
                
                .logoTitle {
                    margin: 0;
                    text-align: start;
                    font-size: 1.575rem;
                    color: $green-1000;
                }
            }
            .buttonSection {
                .linkButton {
                    .button {
                        background-color: $green-50;
                        color: $skin5;
                        border: 0;
                        border-radius: 0.4rem;
                    }
                }
                .button {
                    background-color: $green-50;
                    color: $skin5;
                    border: 0;
                    border-radius: 0.4rem;
                }
            }
        }
        .table-responsive {
            overflow-x: hidden;
            margin-bottom: 3rem !important;
            thead {
                background-color: $green-300;
            }
            .removeAssistance {
                padding: 0;
                
                color: #f62d51;
                background-color: transparent;
                border: none;

                box-shadow: none;
            }
        }
        .cardPagePagination {
            width: 100%;

            display: flex; 
            justify-content: center;

            position: absolute;
            bottom: 2%;
        }
        .cardPageMessage {
            width: 30%;
            padding: 0;
            margin: 0;
        }
    }

    &.dashboards {
        .cardPage {
            padding: 0px;
        }
        .card-header {
            margin: 0px;
            background-color: #236823;

            .card-title {
                color: white;
            }
        }

        .card-text {
            color: black;
        }
    }
}