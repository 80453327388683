.profileImageContainer {
    cursor: pointer;
    height: 6rem;
    width: auto;
    border-radius: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    margin: 0 1rem 1rem 0;

    .label {
        margin: 0;
        height: 100%;
        width: auto;
        border-radius: 5rem;
        .profileImage {
            height: 100%;
            border: 1px solid $green-700;
            opacity: 0.7;
            border-radius: 5rem;
            transition: width 0.5s ease, opacity 0.5s ease;
        }
        .profileImage:hover {
            opacity: 1;
        }
    }
    .label:after {
        content: "Alterar";
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 100%;
        text-align: center;
        border-radius: 5rem;
        margin: auto;
        height: 0.094rem;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        background-color: #000;

        transition: width 0.5s ease, opacity 0.5s ease;
    }

    .label:hover:after {
        cursor: pointer;
        color: white;
        opacity: 0.5;
        .profileImage:hover {
            opacity: 1;
        }
    }
    .input {
        display: none;
    }
}

.columnLink {
    color: #3e5569;
    transition: 0.3s;
    &:hover {
        color: #3e5569cb;
    }
}

.nps-form {
    display: flex;
    // flex-direction: column;
    flex: 1;
    align-items: center;

    label {
        margin-bottom: 0px;
    }

    .nps-input {
        position: initial;
        margin: 0px;
        margin-left: 10px;
    }

    .nps-link {
        color: $body-color;
        margin-left: 10px;
    }
}
